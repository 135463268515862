@font-face {
  font-family: "Market Deco";
  src: local("Market Deco"), url("./fonts/Market_Deco.ttf");
}

@font-face {
  font-family: "Futura Std Medium";
  src: local("Futura Std Medium"), url("./fonts/Futura Std Medium.otf");
}

@font-face {
  font-family: "Futura Std Heavy";
  src: local("Futura Std Heavy"), url("./fonts/FuturaStd-Heavy.otf");
}

@font-face {
  font-family: "DINNextLTArabic-Regular";
  src: local("DINNextLTArabic-Regular"),
    url("./fonts/DINNextLTArabic-Regular.ttf");
}

body {
  margin: 0 !important;
  font-family: "Futura Std Medium", sans-serif !important;
}

.header-container {
  padding: 1% 8% !important;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 16%);
}

.homepage-header-container {
  position: absolute !important;
}

.header {
  margin: 0;
}

/*nav.fixed-top {
  top: 5% !important;
}

.notificationbar {
  width: 100%;
  background: #E50000;
  height: 5%;
  text-align: center;
  position: fixed;
  z-index: 1050;
  padding: 5px 0;
}

.notificationbar a {
  color: #FFFFFF;
  font-size: 18px;
  text-decoration: none;
  font-weight: bold;
}

.notificationbar a:hover {
  text-decoration: underline;
  color: #FFFFFF;
}
*/
.redlogo {
  width: 100px;
}

.navlink {
  font-weight: 700 !important;
}

.navlinklasttree {
  padding-right: 0px !important;
}

.activeNavLink {
  color: #000000;
}

.pagecontainer {
  min-height: 100vh;
  position: relative;
  float: left;
  display: block;
  width: 100%;
  overflow-x: hidden;
}

.pagecontainer-mobile {
  min-height: auto;
}

.home-pagecontainer {
  min-height: 100vh;
  overflow-x: hidden;
}

.home-pagecontainer-mobile {
  min-height: auto;
}

.contct-pagecontainer {
  /*width: 100%;*/
  padding-bottom: 8%;
}

.notfound-pagecontainer {
  min-height: 100vh;
}

.h1_home {
  vertical-align: text-top;
  font-size: 48px;
  color: red;
  text-decoration-style: double;
  text-transform: uppercase;
  line-height: 1.5;
  font-weight: 500;
  font-family: "Market Deco", sans-serif;
  width: 55%;
}

.p_slider_home {
  vertical-align: text-top;
  font-size: 20px;
  color: red;
  text-decoration-style: double;
  text-transform: uppercase;
  line-height: 1.5;
  font-weight: 500;
  font-family: "Market Deco", sans-serif;
  width: 100%;
}

.p_slider_home_overlay {
  vertical-align: text-top;
  font-size: 28px;
  color: red;
  text-decoration-style: double;
  text-transform: uppercase;
  line-height: 1.5;
  font-weight: 700;
  font-family: "Market Deco", sans-serif;
  width: 100%;
  margin: 0 auto;
}

.p_slider_home_desc {
  vertical-align: text-top;
  font-size: 15px;
  color: black;
  line-height: 1.5;
  font-weight: normal;
  font-family: "Market Deco", sans-serif;
  width: 100%;
}

.p_slider_home_desc_overlay {
  vertical-align: text-top;
  font-size: 15px;
  color: #fff;
  line-height: 1.5;
  font-weight: normal;
  font-family: "Market Deco", sans-serif;
  width: 100%;
}

.h1_concept {
  vertical-align: text-top;
  font-size: 48px;
  color: red;
  text-decoration-style: double;
  text-transform: uppercase;
  line-height: 1.5;
  font-weight: 900;
  font-family: "Market Deco", sans-serif;
  width: 100%;
}

.h5_news {
  vertical-align: text-top;
  font-size: 26px;
  color: red;
  text-decoration-style: double;
  text-transform: uppercase;
  line-height: 1.5;
  font-weight: 500;
  font-family: "Market Deco", sans-serif;
  width: 100%;
}

.h1_news {
  vertical-align: text-top;
  font-size: 42px;
  color: red;
  text-decoration-style: double;
  text-transform: uppercase;
  line-height: 1.5;
  font-weight: 500;
  font-family: "Market Deco", sans-serif;
  width: 100%;
}

.h1_white {
  vertical-align: text-top;
  font-size: 48px;
  color: white;
  text-decoration-style: double;
  text-transform: uppercase;
  line-height: 1.5;
  font-weight: 900;
  font-family: "Market Deco", sans-serif;
}

.h1_black {
  text-align: center;
  vertical-align: text-top;
  font-size: 48px;
  color: #000000;
  text-decoration-style: double;
  text-transform: uppercase;
  line-height: 1.5;
  font-weight: 500;
  font-family: "Market Deco", sans-serif;
}

.signupheading {
  text-align: center;
  vertical-align: text-top;
  text-size-adjust: 100;
  font-size: 1.5rem;
  color: #000000;
  text-decoration-style: double;
  text-transform: uppercase;
  line-height: 1;
  padding-top: 2%;
  word-spacing: 5px;
  font-family: "Market Deco", sans-serif;
}

.opninghrs {
  text-align: center;
  vertical-align: text-top;
  text-size-adjust: 100;
  font-size: 1rem;
  color: #000000;
  text-decoration-style: double;
  line-height: 1.5;
  font-family: "Market Deco", sans-serif;
}

.h5_white {
  text-align: justify;
  vertical-align: text-top;
  text-size-adjust: 100;
  size: 20px;
  font-size: larger;
  color: white;
  text-decoration-style: double;
  text-transform: uppercase;
  line-height: 1.5;
  font-weight: 900;
}

.h4_home {
  text-align: justify;
  vertical-align: text-top;
  text-size-adjust: 100;
  font-size: 20px;
  color: black;
  text-decoration-style: double;
  /*text-transform: capitalize;*/
  line-height: 1.5;
  font-weight: normal;
  font-family: "Futura Std Medium", sans-serif;
  width: 55%;
}

.button_home {
  background-color: #fdc341;
  color: red;
  border-color: transparent;
  border-radius: 0.5px;
  padding: 10px 20px;
  font-family: "Futura Std Medium", sans-serif;
}

.concept-en .img_cpt {
  width: 100%;
  height: auto;
  border-top-left-radius: 35%;
  object-fit: contain;
}

.concept-ar .img_cpt {
  width: 100%;
  height: auto;
  border-top-right-radius: 35%;
  object-fit: contain;
}

.img_concept {
  width: 99%;
  height: auto;
}

.cuisinelist-en .image-gallery-slides {
  border: none;
  border-top-left-radius: 35%;
}

.cuisinelist-ar .image-gallery-slides {
  border: none;
  border-top-right-radius: 35%;
}

.menu-en .image-gallery-slides {
  border: none;
  border-top-left-radius: 35%;
}

.menu-ar .image-gallery-slides {
  border: none;
  border-top-right-radius: 35%;
}

.menulistdiv {
  width: 100%;
}
.menuitemimages {
  width: 100%;
  margin-bottom: 5px;
}

.parap {
  color: #fff;
  font-size: 12px;
  padding: 5px 0 0 0 !important;
  font-weight: bold;
}

.mobileMenuParap-en {
  color: #fff;
  font-size: 12px;
  padding: 5px 0 0 0 !important;
  font-weight: bold;
  float: right;
}

.mobileMenuParap-ar {
  color: #fff;
  font-size: 12px;
  padding: 5px 0 0 0 !important;
  font-weight: bold;
  float: left;
}

.cuisinelist-en .image-gallery-thumbnail-image {
  object-fit: cover !important;
  max-height: 150px;
}

.cuisinelist-ar .image-gallery-image {
  width: 100% !important;
  object-fit: cover !important;
  max-height: 420px !important;
}

.cuisinelist-ar .image-gallery-thumbnail-image {
  object-fit: cover !important;
  max-height: 150px;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover {
  border: 2px solid #fff !important;
}

.theteam-en .img_team {
  border-radius: 0 50% 50% 0;
  max-height: 520px;
  object-fit: cover !important;
}

.theteam-ar .img_team {
  border-radius: 50% 0% 0% 50%;
  max-height: 520px;
  object-fit: cover !important;
}

.img_cuisine {
  width: 33%;
  height: auto;
}

.image-gallery-thumbnail {
  width: 33% !important;
  height: auto;
}

.p_concept {
  text-align: justify;
  vertical-align: text-top;
  text-size-adjust: 100;
  color: black;
  text-decoration-style: double;
  /*text-transform: capitalize;*/
  line-height: 1.3;
  font-weight: normal;
  padding: 5px;
  background: #FFC633;
  opacity: 0.9;
}

.p_cuisine {
  padding: 5px;
  background: #13B5CC;
  opacity: 0.9;
}

.p_white {
  text-align: justify;
  vertical-align: text-top;
  text-size-adjust: 100;

  color: white;
  text-decoration-style: double;

  /*text-transform: capitalize;*/
  line-height: 1.3;
  font-weight: normal;
}

.p_white_contact {
  text-align: justify;
  vertical-align: text-top;
  text-size-adjust: 100;
  color: white;
  text-decoration-style: double;
  /*text-transform: capitalize;*/
  line-height: 1;
  font-weight: 500;
  padding-top: 10px;
  font-size: 22px;
}

.button_pink {
  border-radius: 2rem;
  background-color: rgb(224, 40, 101);
  color: white;
  border-color: transparent;
  padding: 5px 15px;
  text-transform: uppercase;
}

.button_orange {
  border-radius: 2rem;
  background-color: #ff0000;
  color: white;
  border-color: transparent;
  padding: 5px 15px;
  text-transform: uppercase;
}

.mvh-100 {
  min-height: 50vh;
}

.mvh-90 {
  min-height: 50vh;
}

.td_white {
  text-align: justify;
  vertical-align: text-top;
  text-size-adjust: 100;
  size: 20px;
  font-size: small;

  color: white;
  text-decoration-style: normal;

  text-transform: uppercase;
  line-height: 1;
  font-weight: 20;
}

.container {
  width: 80%;
  margin: 1em auto;
}

.container nav {
  display: flex;
  justify-content: space-between;
}

.container .links {
  display: flex;
  justify-content: space-between;
}

.container .links a {
  margin: auto 2em;
  color: black;
}

.n-wrapper {
  height: 10vh;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  top: 0;
}

.n-left {
  flex: 1;
  align-items: center;
  justify-content: left;
  display: flex;
  margin-top: -28px;
  padding-left: -60px;
  gap: 2rem;
  color: #7a4495;
}

.n-name {
  font-size: 60px;
  font-weight: bold;
  font-family: cursive;
}

.n-right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 400;
}

.n-list {
  flex: 10;
}

.n-list>ul {
  display: flex;
  gap: 2rem;
  margin-right: 4rem;
}

.n-list>ul :hover {
  color: white;
  border-radius: 8px;
  font-size: 25px;
  cursor: pointer;
  background-color: #e8a0bf;
  margin-right: 5px;
  padding: 4px;
}

.n-button {
  flex: 2;
}

.iconContainer {
  display: flex;
  justify-items: center;
  margin-top: 30px;
  font-weight: 500px;
}

.icon {
  font-size: 20px;
  margin-right: 9px;
  margin-top: -2px;
}

.greyColor {
  color: #626262 !important;
}

.whiteColor {
  color: #ffffff !important;
}

.activeMenuClass {
  color: #000 !important;
}

.theteam-en .crousel-control-button {
  display: block;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 5px;
  background: #ff6e33;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 1px;
  transition: all 0.3s ease-out;
  width: 2em;
  height: 2em;
}

.theteam-en .carousel-control-next,
.carousel-control-prev {
  text-decoration: none !important;
}

.theteam-en .carousel-inner {
  border-radius: 0px 300px 300px 0px;
}

.theteam-ar .crousel-control-button {
  display: block;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 5px;
  background: #ff6e33;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 1px;
  transition: all 0.3s ease-out;
  width: 2em;
  height: 2em;
}

.theteam-ar .carousel-control-next,
.carousel-control-prev {
  text-decoration: none !important;
}

.theteam-en .carousel-item {
  margin-left: 0px;
}

.theteam-ar .carousel-item {
  margin-left: 0px;
}

.theteam-en .carousel-control-next {
  left: 80% !important;
  text-decoration: none;
}

.theteam-ar .carousel-control-next {
  right: 80% !important;
  text-decoration: none;
}

.concept-en .crousel-control-button {
  display: block;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 5px;
  background: #ffc633;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 1px;
  transition: all 0.3s ease-out;
  width: 2em;
  height: 2em;
}

.concept-ar .crousel-control-button {
  display: block;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 5px;
  background: #ffc633;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  text-decoration: none;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 1px;
  transition: all 0.3s ease-out;
  width: 2em;
  height: 2em;
}

.concept-ar .carousel-control-next,
.carousel-control-prev {
  text-decoration: none !important;
}

.concept-en .carousel-item {
  margin-left: 0px;
}

.concept-ar .carousel-item {
  margin-left: 0px;
}

.concept-en .carousel-control-next {
  left: 85% !important;
  text-decoration: none;
}

.concept-ar .carousel-control-next {
  right: 85% !important;
  text-decoration: none;
}

.removeTeamMargin {
  margin-bottom: 9%;
}

.mainDiv {
  display: block;
  overflow-x: hidden;
  float: left;
  width: 100%;
}

.theteam-en .removePadding {
  padding-left: 0px !important;
}

.theteam-ar .removePadding {
  padding-right: 0px !important;
}

.image-gallery-slide .image-gallery-description {
  background: #0ab3c9 !important;
  border-radius: 15px 0 0 15px;
  right: 0px;
  left: unset !important;
}

.rowone-top-buffer {
  margin-top: 20px !important;
}

input.singupforminput {
  border: none;
  border-bottom: 3px solid;
  vertical-align: top;
  width: 100%;
  color: #000000;
  background: url("./images/envelop.png") no-repeat left;
  padding-left: 40px;
  margin-top: 5px;
}

.subsform-ar input.singupforminput {
  border: none;
  border-bottom: 3px solid;
  vertical-align: top;
  width: 100%;
  color: #000000;
  background: url("./images/envelop.png") no-repeat right;
  padding-right: 40px;
}

input.singupforminput:focus {
  outline: none;
}

.addmarginfrombottom {
  margin-bottom: 3%;
}

.sfsubmitbutton {
  background-color: #000000;
  border-radius: 5px;
  color: #ffffff;
  margin-top: 0px;
  padding: 5px 30px;
  border: none;
}

/*** placeholder color ***/
::placeholder {
  color: #000000;
  opacity: 1;
  /* Firefox */
  font-weight: 400;
  font-size: 1.2rem;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #000000;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #000000;
}

.socialshareicon {
  text-align: center;
}

.socialshareimg {
  width: 72px;
  height: 72px;
  padding: 5px;
}

.copyrightext {
  text-align: center;
  font-size: 1rem;
}

.contactfooterimg {
  background: url("./images/contactfooterbackground.png") no-repeat;
  background-size: cover;
  min-height: 150px;
}

.navbar-toggler {
  background-color: #eeeeee !important;
}

.top-buffer {
  padding-top: 9%;
}

.top-buffer-yt {
  padding-top: 0%;
}

.bottomBuffer {
  margin-bottom: 5%;
}

.menuImg {
  width: 100%;
  padding: 5px 0px;
  height: 100%;
}

.image-gallery-thumbnail-label {
  right: 0px;
  width: auto !important;
  background: #0ab3c9 !important;
  border-radius: 15px 0 0 15px;
  left: unset !important;
  text-shadow: none !important;
  font-size: 0.6em !important;
  font-weight: 600;
  line-height: 1em !important;
}

.langswitcher {
  border: none;
  background: #ffffff;
  outline: 0px;
}

.worldlangswitcher {
  background-image: url("./images/icons/icon-language.svg");
}

.header-ar .ms-auto {
  margin-left: 0px !important;
}

.header-en .ms-auto {
  margin-right: 0px !important;
}

.loadingtext {
  position: absolute;
  top: 45%;
  left: 49%;
}

.hideinitially {
  display:none;
}

.contact_desc {
  color: #ffffff;
}

.telephonetitle {
  margin-right: 10px;
}
.contact-ar .telephonetitle {
  float: right;
  margin-left: 10px;
}

.opening-en .openinghrslbl {
  font-weight: normal;
  text-align: right;
}

.opening-en .openinghrsval {
  font-weight: normal;
  text-align: left;
}

.opening-ar .openinghrslbl {
  font-weight: normal;
  text-align: left;
}

.opening-ar .openinghrsval {
  font-weight: normal;
  text-align: right;
}

.image-gallery-slide .image-gallery-description {
  bottom:20px !important;
}

.image-gallery-thumbnail-label {
  top: 80% !important;
}

.image-gallery-thumbnail-inner {
  max-height: 150px;
}

.navlink {
  padding: 10px 25px !important;
}

.cafegooglemap {
  min-height: 350px;
}

.contactfooterimg {
  min-height: 120px;
}

.newslist {
  margin: 15px 0px;
}

.news-en .newslistmore {
  text-align: right;
  margin: 10px 0px;
}

.news-ar .newslistmore {
  text-align: left;
  margin: 10px 0px;
}

.newsdetail-en .newsimgdetails {
  float: left;
  padding-right: 20px;
}

.newsdetail-ar .newsimgdetails {
  float: right;
  padding-left: 20px;
}

.newstaglist {
  padding: 25px 0px;
}

.newsdetail-en a.newstag {
  margin: 10px;
  text-decoration: none;
  float: left;
}

.newsdetail-ar a.newstag {
  margin: 10px;
  text-decoration: none;
  float: right;
}

.newsimgdetails {
  max-width: 33%;
}

.notfoundpara {
  text-align: center;
}

.newsimg {
  width: 100%;
  float: left;
}

.menulist {
  background-color: #13B5CC;
  opacity: 0.9;
  padding: 20px 20px;
  margin: 8px !important;
}

.menulist h4 {
  color: #ffffff;
  font-family: 'Market Deco';
  font-size: 30px;
}

.mobileMenulist {
  background-color: #13B5CC;
  opacity: 0.9;
  margin-top: 10% !important;
  padding-left: 25px !important;
}

.mobileMenulist h4 {
  color: #ffffff;
  font-family: 'Market Deco';
  font-size: 30px;
}

.mobilecuisinelist {
  float: left;
  width: 100%;
  overflow-x: hidden;
  margin-bottom: 1rem;
}

.mobilecuisinelistdiv {
  float: left;
  margin: 0% 0% 5% 0%;
  position: relative;
  padding-right: 3%;
}

.mobilecuisinelistdivspace {
  float: right;
  margin: 0% 0% 5% 0%;
  position: relative;
  padding-left: 3%;
}

.mobilemenuimg {
  max-width: 100%;
  min-width: 100%;
  float: left;
}

.mobilecusinetext {
  position: absolute;
  right: 4%;
  bottom: 3%;
  background: #0AB3C9;
  padding: 8px;
  font-size: small;
  color: #fff;
  border-radius: 45% 0% 0% 45%;
}

.mobilecusinetextspace {
  position: absolute;
  right: -1%;
  bottom: 3%;
  background: #0AB3C9;
  padding: 8px;
  font-size: small;
  color: #fff;
  border-radius: 45% 0% 0% 45%;
}

.closemenu {
  font-size: 2rem;
  color: #ffffff;
  float: left;
  margin-top: 10%;
  font-weight: bold;
}

.loadingcontainer {
  position: relative;
  float: left;
  width: 100%;
  height: 100vh;
}

.subscribebutton {
  text-align: center;
  padding-top: 2%;
}

.staffbio {
  text-align: justify;
  vertical-align: text-top;
  color: #ffffff;
  text-decoration-style: double;
  line-height: 1.3;
  font-weight: normal;
  padding: 5px;
  background: #ff6e33;
  opacity: 0.9;
}

.opninghrscontainer {
  text-align: center;
}

.videodesc_mobile {
  display: none !important;
}

.react-slideshow-container .default-nav {
  width: 50px !important;
  height: 45px !important;
  border-radius: 0px !important;
  background: #000000 !important;
  opacity: 0.4;
}

.react-slideshow-container .default-nav path {
  fill: white;
}

.imageonlyslider {
  min-height: 88vh;
}

/** css for desktop */
@media only screen and (max-width: 1024px) and (min-width: 768px) {
  .concept-en .crousel-control-button {
    padding: 5px;
    font-size: 15px;
  }
  
  .concept-ar .crousel-control-button {
    padding: 5px;
    font-size: 15px;
  }

  .theteam-en .crousel-control-button {
    font-size: 15px;
  }

  .theteam-ar .crousel-control-button {
    font-size: 15px;
  }
  
  .videodesc_mobile {
    display: block !important;
    margin-top: 30px;
  }

  div#overlaybox {
    display: none !important;
  }

  .imageonlyslider {
    min-height: auto;
  }

  .react-slideshow-container .default-nav {
    width: 30px !important;
    height: 30px !important;
  }
}

/** css for mobile **/
@media (max-width: 480px) {

  .h1_white {
      font-size: 32px;
  }

  .theteam .crousel-control-button {
    padding: 0px;
  }

  .newsimgdetails {
      max-width: 100%;
  }

  .newsdetail-en .newsimgdetails {
    padding-right: 0px;
  }

  .navlink {
    padding: 10px 0px !important;
  }

  .socialshareimg {
    width: 48px;
    height: 48px;
  }

  .contactfooterimg {
    min-height: 75px;
  }

  .navbrad-logo {
    margin-left: 0px !important;
  }

  .top-buffer {
    padding-top: 30%;
  }

  .top-buffer-yt {
    padding-top: 15%;
  }

  .h1_home {
    font-size: 32px;
    width: 100%;
  }

  .h1_black {
    font-size: 32px;
  }

  .h1_concept {
    font-size: 32px;
    width: 100%;
  }

  .h4_home {
    width: 100%;
    min-height: 220px;
  }

  .navbar-collapse {
    min-height: 100vh;
  }

  .greyColor {
    color: #626262 !important;
  }

  .activeMenuClass {
    color: #000000 !important;
  }

  .openingtimeheading {
    font-size: 32px !important;
  }

  .videodesc_mobile {
    display: block !important;
    margin-top: 30px;
  }

  div#overlaybox {
    display: none !important;
  }

  .imageonlyslider {
    min-height: auto;
  }

  .react-slideshow-container .default-nav {
    width: 30px !important;
    height: 30px !important;
  }
}

@media only screen and (max-width: 767px) and (min-width: 481px) {
  .theteam .crousel-control-button {
    padding: 0px;
    width: 100%;
  }

  .h1_white {
    font-size: 32px;
  }

  .h1_black {
    font-size: 32px;
  }
  
  .socialshareimg {
    width: 48px;
    height: 48px;
  }
  
  .contactfooterimg {
    min-height: 75px;
  }

  .navlink {
    padding: 10px 0px !important;
  }

  .concept-en .crousel-control-button{
    padding: 0px;
  }

  .concept-ar .crousel-control-button {
    padding: 0px;
  }

  .socialshareimg {
    width: 48px;
    height: 48px;
  }

  .contactfooterimg {
    min-height: 75px;
  }

  .openingtimeheading {
    font-size: 32px !important;
  }

  .navbrad-logo {
    margin-left: 0px !important;
  }

  .header-container {
    background-color: #ffffff !important;
    background: none;
    opacity: 1;
  }

  .navbar-collapse {
    min-height: 100vh;
  }

  .greyColor {
    color: #626262 !important;
  }

  .activeMenuClass {
    color: #000000 !important;
  }

  .videodesc_mobile {
    display: block !important;
    margin-top: 30px;
  }

  div#overlaybox {
    display: none !important;
  }

  .imageonlyslider {
    min-height: auto;
  }

  .react-slideshow-container .default-nav {
    width: 30px !important;
    height: 30px !important;
  }
}

.player-wrapper {
  position: relative;
  height: auto;
  padding-top: 56.25%;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.box {
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 10%;
  left: 0;
  opacity: 0.8;
}

.overlay {
  z-index: 9;
  margin: 0 auto;
  background: #000000;
}

